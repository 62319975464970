<template>

  <div class="side">

    <div v-if="hasError">
      {{ t('someWentWrong') }}: {{ errorMessage }}
    </div>

    <div v-else>

      <div v-if="isLoading" class="loading">
        <div class="spinner"></div>
        {{ t('loading.loading') }}
      </div>

      <div v-else>

        <div class="row">
          <div class="col-lg-6">
            <div class="input-wrap">
              <label for="name">{{ t('categoryName') }} <span class="requiredStar">*</span></label>
              <input
                v-model="state.name"
                type="text"
                class="form-control"
                :placeholder="t('categoryName')"
                id="name"/>
              <div v-if="hasNameError" class="error-msg">{{ t('enterCategoryName') }}</div>
            </div>
          </div>
          <div class="col-lg-6">
            <ImageInput v-model="state.image" :has-error="hasImageError" :label="t('categoryImg')" :required="true"/>
          </div>
        </div>

        <TagsInput v-model="state.shopify_collection_id" :items="collections" :label="t('categoryCollections')"/>

        <div class="my-3">
          <MySwitch v-model="state.is_active" :label="t('isCategoryStatusActive')"/>
        </div>

        <div class="btnSet right">

          <router-link :to="{name: 'Index.Page'}" class="btn btn-danger">
            {{ t('cancel') }}
          </router-link>

          <a @click="onSubmit" :disabled="isFormInvalid" class="btn btn-primary">
            {{ submitting ? t('adding') : t('add') }}
          </a>

        </div>

      </div>

    </div>

  </div>

</template>

<script>

import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import ImageInput from '../ImageInput'
import TagsInput from '../TagsInput'
import MySwitch from '../MySwitch'
import { computed, onMounted, reactive, ref } from 'vue'
import useGlobal from '../../composables/useGlobal'
import { useRouter } from 'vue-router'

export default {
  components: { MySwitch, ImageInput, TagsInput },

  setup() {

    const {
      t,

      restApi,

      successNotify,
      errorNotify,

      emitRefreshCategories,

    } = useGlobal()

    const router = useRouter()

    const hasError = ref(false)
    const errorMessage = ref('')
    const isLoading = ref(false)

    const submitting = ref(false)

    const state = reactive({
      name: '',
      is_active: true,
      image: {
        name: '',
        data: '',
        url: '',
        has_changed: false,
      },
      shopify_collection_id: [],
    })

    const rules = {
      name: { required },
      image: { name: { required }, data: { required } },
    }

    const collections = ref([])

    const $v = useVuelidate(rules, state, { $autoDirty: true })

    const hasNameError = computed(() => {
      return $v.value.name.$error
    })

    const hasImageError = computed(() => {
      return $v.value.image.$error
    })

    const isFormInvalid = computed(() => {
      return $v.value.$invalid || submitting.value
    })

    const onSubmit = () => {

      submitting.value = true

      restApi.addCategorySubmit(state.name, state.image.name, state.image.data, state.is_active,
        state.shopify_collection_id).then(() => {

        successNotify(t('categoryCreated'), t('categoryAdded'))

        emitRefreshCategories()

        router.replace({
          name: 'Index.Page',
        })

      }).catch(error => {

        submitting.value = false

        errorNotify(t('categoryNotCreated'), t('categoryCreatingError') + error.message)

      })

    }

    onMounted(() => {

      isLoading.value = true
      restApi.fetchCollections().then(response => {
        collections.value = response.getItems()
        isLoading.value = false
      }).catch(error => {
        hasError.value = true
        isLoading.value = false
        errorMessage.value = error.toString()
      })

    })

    return {

      hasError,
      errorMessage,
      isLoading,

      isFormInvalid,

      collections,

      submitting,

      onSubmit,

      t,

      state,

      hasNameError,
      hasImageError,
    }
  },

}
</script>

<style scoped>

.requiredStar {
  color: red;
}

[disabled="true"] {
  pointer-events: none;
  color: #fff;
  background-color: lightgray;
  border: 1px solid lightgray;
}

[disabled="false"] {
  color: #fff;
  cursor: pointer;
}

</style>
